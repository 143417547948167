import { createStitches, defaultThemeMap } from '@stitches/react';

const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = createStitches({
  prefix: 'glimpse',

  theme: {
    // Colors
    colors: {
      'red-120': '#feeded',
      'red-130': '#fcd5d0',
      'red-400': '#e84a4a',
      'red-500': '#c51c3a',
      'red-800': '#850231',
      'red-900': '#580A2D',

      'teal-100': '#a5e9ed',
      'teal-600': '#006776',

      'purple-100': '#d7cfff',
      'purple-700': '#5f2fe9',

      'grey-100': '#f9f8f8',

      'light-200': 'rgba(255, 255, 255, .2)',
      'light-400': 'rgba(255, 255, 255, .4)',
      'light-800': 'rgba(255, 255, 255, .8)',
      'light-1000': '#fff',

      'dark-80': 'rgba(0, 0, 0, .08)',
      'dark-200': 'rgba(0, 0, 0, .2)',
      'dark-400': 'rgba(0, 0, 0, .4)',
      'dark-640': 'rgba(0, 0, 0, .64)',
      'dark-800': 'rgba(0, 0, 0, .8)',
      'dark-1000': '#000'
    },

    // Spacing & sizes
    space: {
      wee: '.25rem',
      tiny: '.5rem',
      small: '.75rem',
      medium: '1rem',
      large: '1.5rem',
      huge: '2.5rem'
    },

    sizes: {
      wee: '1rem',
      tiny: '1.5rem',
      small: '2rem',
      medium: '2.5rem',
      large: '3rem'
    },

    // Typography
    // NOTE: the typography tokens are diverging from out general token naming convention,
    // since they are not meant to be used directly, instead we should be using the typography
    // helpers for `text` and `heading` which will return the right combination of style
    // attribute groups (e.g. font size, color, line height, etc)
    fontSizes: {
      xs: '.625rem',
      small: '.75rem',
      medium: '.875rem',
      large: '1rem',
      xl: '1.25rem',
      xxl: '1.5rem',
      xxxl: '2rem',
      '4xl': '2.75rem',
      '5xl': '4rem'
    },

    lineHeights: {
      xs: '.75rem',
      small: '1rem',
      medium: '1.125rem',
      large: '1.25rem',
      xl: '1.625rem',
      xxl: '2rem',
      xxxl: '2.5rem',
      '4xl': '3.375rem',
      '5xl': '4.5rem'
    },

    letterSpacings: {
      xxxs: '-.04rem',
      xxs: '-.03rem',
      xs: '-.02rem',
      small: '-.01rem',
      medium: 0,
      large: '.02rem'
    },

    fontWeights: {
      regular: 400,
      bold: 700
    },

    fontFamilies: {
      sans:
        `Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,` +
        `"Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
      mono: `"Maison Neue Mono", Menlo, Monaco, Consolas, Liberation Mono, "Courier New", monospace`
    },

    // Borders
    borderWidths: {
      thin: '.0625rem'
    },

    radii: {
      wee: '.125rem',
      tiny: '.25rem',
      small: '.5rem',
      medium: '.75rem',
      large: '1rem',
      huge: '1.5rem'
    },

    // Shadows
    shadows: {
      none: '0 0 0 rgba(0, 0, 0, 0)',
      small: '0 0 .25rem rgba(0, 0, 0, 0.2)',
      medium: '0 0 .5rem rgba(0, 0, 0, 0.2)'
    },

    // Animations
    timings: {
      fast: '.1s',
      normal: '.2s',
      slow: '.3s'
    },

    easings: {
      linear: 'linear'
    },

    transitions: {
      'fade-in-out': 'opacity $timings$normal',
      'slide-in-out': 'transform $timings$normal',
      'box-shadow': 'box-shadow $timings$normal'
    },

    // Focus outline helper
    focusOutline: {
      width: '.2rem',
      color: '$colors$dark-400'
    }
  },

  media: {
    'bp-small': '(min-width: 600px)',
    'bp-medium': '(min-width: 905px)',
    'bp-large': '(min-width: 1240px)',
    'bp-huge': '(min-width: 1440px)',

    'reduced-motion': '(prefers-reduced-motion: reduce)',
    'is-touch-device': '(hover: none)'
  },

  themeMap: {
    ...defaultThemeMap
  }
});

export { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config };
