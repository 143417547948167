// Polyfilling globalThis manually here, because polyfill.io doesn't seem to cover all
// edge versions that need the polyfill, which will make stitches break meaning it's pretty
// critical :|
import 'core-js/features/global-this';

import Head from 'next/head';

import { globalCss, initGlobalStyles } from '~/utils/styling';

import appleTouchIcon from '~/assets/favicon/apple-touch-icon-180x180.png';
import favicon16 from '~/assets/favicon/favicon-16x16.png';
import favicon32 from '~/assets/favicon/favicon-32x32.png';

import type { AppProps } from 'next/app';

initGlobalStyles();

const overrideGlobalStyles = globalCss({
  body: { background: 'transparent' }
});

function VouchApp({ Component, pageProps }: AppProps) {
  overrideGlobalStyles();
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16.src} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32.src} />
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon.src} />
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default VouchApp;
