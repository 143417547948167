/* eslint-disable @typescript-eslint/no-var-requires */

import roobertRegularWoff2Path from '~/assets/fonts/Roobert/Regular.woff2';
import roobertSemiBoldWoff2Path from '~/assets/fonts/Roobert/SemiBold.woff2';

const fonts = {
  '@font-face': [
    {
      fontFamily: 'Roobert',
      src: `local('Roobert'), ` + `url('${roobertRegularWoff2Path}') format('woff2')`,
      fontWeight: 400,
      fontStyle: 'normal',
      fontDisplay: 'swap' as const
    },
    {
      fontFamily: 'Roobert',
      src: `local('Roobert'), ` + `url('${roobertSemiBoldWoff2Path}') format('woff2')`,
      fontWeight: 600,
      fontStyle: 'normal',
      fontDisplay: 'swap' as const
    }
  ]
};

export { fonts };
