const textVariants = {
  small: {
    fontSize: '$fontSizes$small',
    lineHeight: '$lineHeights$small',
    letterSpacing: '$letterSpacings$medium'
  },
  medium: {
    fontSize: '$fontSizes$medium',
    lineHeight: '$lineHeights$medium',
    letterSpacing: '$letterSpacings$medium'
  },
  large: {
    fontSize: '$fontSizes$large',
    lineHeight: '$lineHeights$large',
    letterSpacing: '$letterSpacings$small'
  },
  huge: {
    fontSize: '$fontSizes$xl',
    lineHeight: '$lineHeights$xl',
    letterSpacing: '$letterSpacings$xs'
  }
};

type TextArg = {
  size: keyof typeof textVariants;
  color?: 'grey';
};

function text({ size, color }: TextArg) {
  return {
    ...textVariants[size],
    ...(color === 'grey' ? { color: '$dark-640' } : {})
  };
}

export { text, textVariants };
