import { fonts } from './fonts';
import { reset } from './reset';
import { globalCss } from './theme';
import { text } from './typography/text';

const initGlobalStyles = globalCss({
  ...reset,
  ...fonts,

  // TODO: Figure out a nicer way to get rounded, inset, solid, black borders for focus
  // https://stackoverflow.com/questions/5394116/outline-radius
  // https://developer.mozilla.org/en-US/docs/Web/CSS/outline#accessibility_concerns
  '*:focus': {
    outlineColor: '$focusOutline$color',
    outlineWidth: '$focusOutline$width'
  },

  'html, body': {
    // We need 100% height on the html and body so that smaller pages definitely grow
    // (minHeight does not seem to work properly for this :/)
    height: '100%'
  },

  '#__next': {
    // Min width for the NextJS container, again to make sure it grows to at least the
    // viewport height, but also potentially larger if we want it to
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },

  body: {
    fontFamily: '$fontFamilies$sans',
    fontWeight: '$fontWeights$regular',
    ...text({ size: 'medium' }),

    backgroundColor: '$light-1000',
    color: '$dark-1000',

    // Disable tap highlighting on mobile, our styles should still be accessible without it
    '-webkit-tap-highlight-color': 'transparent',

    // Hide MS Edge password show/hide button, since we have our own
    'input::-ms-reveal, input::-ms-clear': {
      display: 'none'
    },

    // Placeholder
    '::placeholder': {
      color: '$dark-640'
    },

    '&.noscroll, &.onboarding-noscroll': {
      overflow: 'hidden'
    },

    // Explicit button text color setting
    // Fixes iOS 15 issue that would default to a system colour: https://developer.apple.com/forums/thread/690529
    button: {
      cursor: 'pointer',
      margin: 0,
      color: '$dark-1000'
    }
  },

  'input, textarea, select:focus': {
    // HACK: Increase input font size for mobile to at least 1rem (=16px), ideally we increase
    // general body font size for mobile instead
    // Fixes zoom focus issue on iOS: https://stackoverflow.com/a/6394497/10293336
    ...text({ size: 'large' }),
    '@bp-small': {
      ...text({ size: 'medium' })
    }
  },

  p: {
    margin: '$tiny 0',

    '&:first-child': {
      marginTop: 0
    },

    '&:last-child': {
      marginBottom: 0
    }
  },

  a: {
    color: '$dark-640',
    textDecoration: 'underline',
    cursor: 'pointer',

    '&:hover, &:focus': {
      color: '$dark-1000'
    }
  },

  // TODO: Not sure if we even want to bother with this, or could do it better with JS
  // Try to keep blocking interactions if user edits the DOM
  '.onboarding-blur *': {
    pointerEvents: 'none'
  }
});

export { initGlobalStyles };
